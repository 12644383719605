import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import tos from "../tos.md";

const Footer = () => {
  const [modal, setModal] = useState(false);
  const [markdown, setMarkdown] = useState("");

  const openModal = () => {
    fetch(tos)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer">
      <a className="outline-btn" onClick={scrollToTop}>
        Back to top <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
      </a>
      <a onClick={openModal} className="tos">
        Terms of Service
      </a>
      <p>&copy; Hoya Developers 2023.</p>
      <Modal
        isOpen={modal}
        onRequestClose={closeModal}
        contentLabel="Terms of Service">
        <ReactMarkdown className="markdown" children={markdown}></ReactMarkdown>
        <a href="#" className="outline-btn modal-btn" onClick={closeModal}>
          Close
        </a>
      </Modal>
    </div>
  );
};

export default Footer;
