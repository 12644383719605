import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const Info = () => {
  return (
    <div className="info">
      <div className="container">
        <h6>Why join Hoya Developers?</h6>
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce>
          <div className="info-row info-row-odd">
            <div className="info-row-text info-row-text-odd">
              <h3>Project-Based Technical Experience</h3>
              <p>
                Students interested in coding or UI/UX Design receive the unique
                opportunity to gain experience working collaboratively on
                developing a software application.
              </p>
              <p>
                After one semester, all members have an outstanding technical
                project to add to their portfolio, allowing them to stand out
                from other students/applicants to technical positions.
              </p>
            </div>
            <img src="svgs/why1.svg" alt="computer"></img>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce>
          <div className="info-row info-row-even">
            <div className="info-row-text info-row-text-even">
              <h3>Learn Industry Standards</h3>
              <p>
                Throughout the semester, members become familiar with important
                software development tools and industry frameworks that aren’t
                taught in a classroom. Our training and hands-on learning serves
                as the perfect complement to the computer science curriculum.
              </p>
            </div>
            <img src="svgs/why2.svg" alt="computer"></img>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce>
          <div className="info-row info-row-odd">
            <div className="info-row-text info-row-text-odd">
              <h3>Extended Mentorship</h3>
              <p>
                All members receive one-on-one mentorship throughout the entire
                development process. We promote a culture of meaningful feedback
                and collaboration. Members go from having limited experience to
                confident developers in just one semester, ready to apply for
                the most lucrative technical internships.
              </p>
            </div>
            <img src="svgs/why3.svg" alt="computer"></img>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce>
          <div className="info-row info-row-even">
            <div className="info-row-text info-row-text-even">
              <h3>Strong Social Community</h3>
              <p>
                We are striving to create a greater social culture among
                computer science students at Georgetown. Hoya Developers is the
                hub for comp sci students outside of the classroom to interact
                and attend social events, fostering a tight-knit community
                throughout the entire computer science major.
              </p>
            </div>
            <img src="svgs/why4.svg" alt="computer"></img>
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default Info;
