import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { useState } from "react";

const Projects = () => {
  const [active, setActive] = useState("s24");
  const f23projects = [
    {
      name: "Amari Dating",
      description:
        "Amari takes a unique approach to a traditional dating app, establishing parternships with restaurants to focus on meeting rather than messaging. This semester, we are working with Amari to build out their initial mobile application, which will allow users to create profiles, match with other users, and redeem promotions at restaurants.",
      technologies: ["Flutter", "Firebase", "Figma"],
      link: "#",
      image: "/projects/amari.png",
    },

    {
      name: "Hilltop Microfinance Initiative",
      description:
        "HMFI aims to empower individuals by promoting financial independence and stimulating economic growth in the DC area. This semester, we are working with HMFI to completely redesign their existing website, which will allow users to apply for loans, learn about the club organization, and to make donations.",
      technologies: ["React", "SCSS", "Stripe API", "Podio"],
      link: "https://hilltopmfi.org/",
      image: "/projects/hmfi.png",
    },
    {
      name: "Allowance",
      description:
        "Allowance offers college students spending money to use at partnered stores and restaurants by driving business to these establishments. This semester, we are working with Allowance to scale their initial MVP and to build out an analytics dashboard for businesses to onboard themselves and track their earnings through the platform. ",
      technologies: ["Flutter", "Firebase", "Figma", "R"],
      link: "https://www.allowance.fund/",
      image: "/projects/allowance.png",
    },
    {
      name: "Map Collective",
      description:
        "Founded by Forbes' 30 Under 30 Tara Gupta, Map Collective is building a digital twin of the Earth to develop visualizations of the world's most pressing climate issues. This semester, we are working with Map Collective to build out front-end mapping templates from various data sources.",
      technologies: ["Wordpress", "PHP", "Node", "ArcGIS", "SQL"],
      link: "https://mapcollective.com",
      image: "/projects/map_collective.png",
    },
    {
      name: "NAIMUN",
      description:
        "NAIMUN is the largest high school model UN conference in the country. This semester, we are building a registration portal for delegations to use to register, as well as for administrators use to create and assign committees, manage delegates, and collect payment.",
      technologies: ["React", "NodeJS", "SQL", "Figma"],
      link: "https://naimun.modelun.org/",
      image: "/projects/naimun.png",
    },
    {
      name: "OpenGrid Technologies",
      description:
        "OpenGrid is revolutionizing traditional EV charging solutions by building a network of lithium-ion chargers targeted to urban areas. This semester, we are working with OpenGrid to build out a mobile application that will allow users to locate and reserve chargers, and to track their charging progress.",
      technologies: ["Swift", "Figma", "AWS"],
      link: "https://opengridtech.com/",
      image: "/projects/opengrid.png",
    },
    {
      name: "Poly Platform",
      description:
        "Poly Platform is designed to digitize local governments by building products for 100,000s of residents to engage with their cities. This semester, our team is building features to expand the platform.",
      technologies: ["Ionic", "Capacitor", "React", "Figma"],
      link: "https://getpolyplatform.com/",
      image: "/projects/poly.png",
    },
  ];

  const s24projects = [
    {
      name: "Fitted Collective",
      description:
        'Founded by an ex-Amazon Sales engineer, Fitted attempts to marry social media and "fit checks". This semester we implemented an inverted search index structure on the front-end. We also implmemented an image segmentation model to allow virtual try-ons.',
      technologies: ["React", "Firebase", "AWS"],
      image: "/projects/fitted.png",
      link: "https://fitted.app/",
    },
    {
      name: "Truckable",
      description:
        "Truckable is a platform that connects truckers with shippers. This semester, we built their mobile application, which allows truckers to find and accept jobs, and shippers to post jobs and track their progress.",
      technologies: ["React Native", "Supabase", "Figma"],
      image: "/projects/freight-trucker.png",
      link: "#",
    },
    {
      name: "Pavilion",
      description:
        "Pavilion is a platform that connects students studying abroad. This semester, we built their mobile application, which allows students to find other students studying abroad in the same city, and to connect with them.",
      technologies: ["React", "Firebase", "Figma"],
      image: "/projects/pavilion.png",
      link: "#",
    },

    {
      name: "GoodOnBaby",
      description:
        "GoodOnBaby is a boutique baby goods online store. This semester, we built an mvp for a new feature that allows parents to add their child's details and get personalized and customizable bundles of baby goods added to their cart.",
      technologies: ["React", "Shopify", "Firebase", "Figma"],
      image: "/projects/goodonbaby.png",
      link: "https://goodonbaby.com/",
    },
    {
      name: "Jammin' Together",
      description:
        "Jammin' Together is a platform for artists and music venues. This semester, we built their website and mobile application, which allows artists to find venues to perform at, and venues to find artists to perform.",
      technologies: ["React Native", "Firebase", "Figma"],
      image: "/projects/jammin.png",
      link: "https://www.jammintogether.com/",
    },
    {
      name: "NAIMUN",
      description:
        "NAIMUN is the largest student-run Model UN conference in the nation. This year, we built their custom registration system, which allows delegates to register for the conference and administrators to manage the conference.",
      technologies: ["NextJS", "Supabase", "Figma"],
      image: "/projects/naimun.png",
      link: "https://naimunregistration.org/",
    },
    {
      name: "Goalacity",
      description:
        "Goalacity is a goal tracking mobile application. This semester, we built out their initial MVP, which allows users to create goals, track their progress, and share their goals with friends.",
      technologies: ["React Native", "Supabase", "Figma"],
      image: "",
      link: "#",
    },
    {
      name: "Watch & Shop",
      description:
        "Watch & Shop aims to revolutionize the way we shop by allowing users to purchase items directly from their favorite TV shows. This semester, we built a chrome plugin that periodically takes screenshots and returns a list of objects found.",
      technologies: ["React Native", "AWS", "Firebase", "Figma"],
      image: "",
      link: "#",
    },
  ];

  return (
    <div className="projects">
      <div className="container">
        <h3> Our Past Projects</h3>
        <div className="projects-buttons">
          <button
            className={
              active == "s24" ? " project-button-active " : "project-button"
            }
            onClick={() => setActive("s24")}
          >
            Spring 2024
          </button>
          <button
            className={
              active == "f23" ? "project-button-active" : "project-button"
            }
            onClick={() => setActive("f23")}
          >
            Fall 2023
          </button>
        </div>
        <h6>
          Read about our previous clients and what our teams have built. Fall
          2024 projects will be announced soon.
        </h6>

        <div className="projects-placeholder-container">
          <h4>Want to become a client?</h4>
          <p>Read below. We'd love to work with you.</p>
          <h6>
            <FontAwesomeIcon icon={faArrowDown} />
          </h6>
        </div>
        <div className="projects-container">
          {active == "f23"
            ? f23projects.map((project, index) => (
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  delay={50 * index}
                >
                  <div className="project">
                    <img
                      src={project.image}
                      alt="project"
                      className="project-image"
                    />
                    <div className="project-name">
                      <h4>{project.name}</h4>
                      {project.link != "#" && (
                        <a href={project.link} target="_blank">
                          Website <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                      )}
                    </div>

                    <hr></hr>
                    <p>{project.description}</p>
                    <div className="project-items">
                      {project.technologies.map((technology) => (
                        <p>{technology}</p>
                      ))}
                    </div>
                  </div>
                </AnimationOnScroll>
              ))
            : s24projects.map((project, index) => (
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  delay={50 * index}
                >
                  <div className="project">
                    {project.image != "" && (
                      <img
                        src={project.image}
                        alt="project"
                        className="project-image"
                      />
                    )}
                    <div className="project-name">
                      <h4>{project.name}</h4>
                      {project.link != "#" && (
                        <a href={project.link} target="_blank">
                          Website <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                      )}
                    </div>

                    <hr></hr>
                    <p>{project.description}</p>
                    <div className="project-items">
                      {project.technologies.map((technology) => (
                        <p>{technology}</p>
                      ))}
                    </div>
                  </div>
                </AnimationOnScroll>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
