import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Team = () => {
  const team = [
    {
      name: "Reed Uhlik",
      image: "headshots/reed.png",
      title: "President",
      year: "CAS | '25",
      linkedin: "https://www.linkedin.com/in/reed-uhlik/",
      email: "rcu8@georgetown.edu",
    },
    {
      name: "Matt Jordan",
      image: "headshots/matt.jpeg",
      title: "Vice President",
      year: "CAS | '26",
      linkedin: "https://www.linkedin.com/in/matthew-jordan-49a46b24a/",
      email: "mdj82@georgetown.edu",
    },
    {
      name: "Leo Ledlow",
      image: "headshots/leo.jpeg",
      title: "Vice President",
      year: "CAS | '27",
      linkedin: "https://www.linkedin.com/in/jledlow/",
      email: "jdl162@georgetown.edu",
    },
    {
      name: "Amanda Hao",
      image: "headshots/amanda.jpeg",
      title: "Technical PM",
      year: "SFS | '26",
      linkedin: "https://www.linkedin.com/in/amandahao/",
      email: "hoyadevelopers@gmail.com",
    },
    {
      name: "Chris Tenegy",
      image: "headshots/chris.jpeg",
      title: "Technical PM",
      year: "CAS | '26",
      linkedin: "https://www.linkedin.com/in/christopher-tengey-12555b24a/",
      email: "cdt50@georgetown.edu",
    },
    {
      name: "Henry Deng",
      image: "headshots/henry.jpeg",
      title: "Technical PM",
      year: "CAS | '26",
      linkedin: "https://www.linkedin.com/in/zezhi-deng/",
      email: "zd127@georgetown.edu",
    },
    {
      name: "Jeffrey Gao",
      image: "headshots/jeffrey.jpeg",
      title: "Technical PM",
      year: "CAS | '25",
      linkedin: "https://www.linkedin.com/in/jeffrey-gao-88589215b/",
      email: "jpg135@georgetown.edu",
    },
    {
      name: "Sara Lignell",
      image: "headshots/sara.jpeg",
      title: "Technical PM",
      year: "CAS | '26",
      linkedin: "https://www.linkedin.com/in/sara-lignell-5b1b0b1b1/",
      email: "srl84@georgetown.edu",
    },
  ];

  return (
    <div className="team">
      <div className="container">
        <h4>Our Leadership Team</h4>
        <p className="team-subtitle">Meet Georgetown's top CS talent.</p>

        <div className="team-content">
          {team.map((member, index) => (
            <AnimationOnScroll animateIn="animate__fadeInUp" delay={index * 50}>
              <div className="team-member">
                <img src={member.image} alt="Headshot" />
                <h5>{member.name}</h5>
                <h6>{member.title}</h6>
                {member.project && <h6 className="italic">{member.project}</h6>}
                <p>{member.year}</p>
                <div className="team-member-links">
                  <a href={member.linkedin}>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  <a href={"mailto:" + member.email}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </div>
              </div>
            </AnimationOnScroll>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
