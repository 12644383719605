import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const Technologies = () => {
  const technologies = [
    {
      name: "React",
      image: "icons/react.svg",
    },
    {
      name: "Node.js",
      image: "icons/node.svg",
    },
    {
      name: "MongoDB",
      image: "icons/mongo.svg",
    },
    {
      name: "GitHub",
      image: "icons/github.svg",
    },
    {
      name: "AWS",
      image: "icons/aws.svg",
    },
    {
      name: "Figma",
      image: "icons/figma.svg",
    },
    {
      name: "Firebase",
      image: "icons/firebase.svg",
    },
    {
      name: "Flutter",
      image: "icons/flutter.svg",
    },
    {
      name: "Monday",
      image: "icons/monday.svg",
    },
    {
      name: "Swift",
      image: "icons/swift.svg",
    },
    {
      name: "Express",
      image: "icons/express.svg",
    },
    {
      name: "Tensorflow",
      image: "icons/tensorflow.svg",
    },
  ];

  return (
    <div className="technologies">
      <div className="technologies-content">
        <div className="container">
          <div className="technologies-content-left">
            <h6>Learn By Doing</h6>
            <img src="svgs/learning.svg" alt="computer"></img>
          </div>

          <div className="technologies-content-right">
            <h3>
              Gain hands-on experience with the latest industry technologies and
              development tools.
            </h3>
            <div className="technologies-content-right-list">
              {technologies.map((technology, index) => (
                <AnimationOnScroll
                  animateIn="animate__zoomIn"
                  delay={index * 60}>
                  <div className="technologies-content-right-list-item">
                    <h5>{technology.name}</h5>
                    <img src={technology.image} alt={technology.name}></img>
                  </div>
                </AnimationOnScroll>
              ))}
            </div>
          </div>
        </div>
      </div>
      <img
        className="technologies-bottom"
        src="svgs/technologies_bottom.svg"
        alt="technologies"></img>
    </div>
  );
};

export default Technologies;
