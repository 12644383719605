import React from "react";
import { useState, useEffect } from "react";
//import caret from react-icons
import {
  FaChevronRight,
  FaChevronLeft,
  FaUpload,
  FaCheck,
  FaCircleCheck,
} from "react-icons/fa6";
import "animate.css";
import { HashLoader } from "react-spinners";
import useEventListener from "@use-it/event-listener";

const InfoSession = () => {
  const [netID, setNetID] = useState("");
  const [showModal, setShowModal] = useState(0);
  const [modalMessage, setModalMessage] = useState("Working...");

  const handleSubmit = () => {
    //scroll page to top smoothly
    window.scrollTo({ top: 0, behavior: "smooth" });
    //show modal
    setShowModal(1);

    //call the /api/addNetID endpoint
    fetch("/api/infoSession", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ netID: netID }),
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else {
          throw new Error("Something went wrong :(");
        }
      })
      .then(() => {
        setModalMessage("Thanks!");
        setShowModal(2);
      })
      .catch((err) => {
        console.log(err);
        setModalMessage("Something went wrong :(");
        setShowModal(2);
      });
  };

  useEventListener("keydown", (event) => {
    if (event.key == "Enter") {
      if (netID != "" && showModal == 0) {
        handleSubmit();
      } else {
        resetForm();
      }
    }
  });

  const resetForm = () => {
    setNetID("");
    setShowModal(0);
    setModalMessage("Working, hang tight...");
  };

  return (
    <div className="application-page">
      <img
        src="svgs/application-top-image.svg"
        className="application-top-image"
      />
      <img
        src="svgs/application_bottom.svg"
        className="application-bottom-image"
      />
      <div className="application-page-container cab-fair-container">
        <div className="application-page-left">
          <h4>Info Session Sign-in</h4>
          <h1>Hoya Developers</h1>
        </div>
        <div className="application-page-right cab-fair-right">
          {showModal !== 0 && (
            <div className="application-page-modal animate__animated animate__fadeIn">
              <div className="application-page-modal-content">
                {showModal == 1 && (
                  <HashLoader
                    color={"#86D600"}
                    loading={true}
                    size={50}
                    speedMultiplier={1}
                  />
                )}
                {showModal == 2 && (
                  <FaCircleCheck className="application-page-modal-check" />
                )}
                <h3>{modalMessage}</h3>
                {showModal == 2 && (
                  <div>
                    <button onClick={() => resetForm()}>Close</button>
                  </div>
                )}
              </div>
            </div>
          )}
          <input
            type="text"
            className="netid-input"
            placeholder="Your NetID..."
            value={netID}
            onChange={(e) => setNetID(e.target.value)}
          />
          <input
            type="submit"
            className="submit"
            value="Submit!"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoSession;
