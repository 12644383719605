import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "animate.css/animate.min.css";
import { Link } from "react-router-dom";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Landing = () => {
  return (
    <div className="landing">
      <div className="container">
        <div className="logo-top">
          <img src="logo.png" alt="logo" />
          <h3>Hoya Developers</h3>
        </div>
        <h1 className="animate__animated animate__fadeIn landing-title">
          Georgetown's top pre-professional and social community for those
          interested in computer science.
        </h1>
        <h4 className="landing-subtitle">
          Through hands-on learning, collaborative development projects, and
          lucrative networking opportunities, Hoya Devs offers valuable
          experiences that help members land top internships and full-time jobs.
        </h4>
        <div className="landing-buttons">
          <a href="join" className="landing-button-filled">
            Join Now!
          </a>
        </div>
        <div className="landing-graphics">
          <img src="svgs/landing_bottom.svg" alt="landing" />
          <img src="svgs/landing_bottom2.svg" alt="landing" />
        </div>
      </div>
      <img
        src="svgs/landing_bottom_bg.svg"
        alt="landing"
        className="landing-bg-bottom"
      />
    </div>
  );
};

export default Landing;
