import React from "react";
import "animate.css/animate.min.css";

const Vision = () => {
  return (
    <div className="vision-content">
      <div className="container">
        <div className="vision-wrapper">
          <div className="vision-img">
            <img src="svgs/vision_left.svg" alt="vision" />
          </div>
          <div className="vision-text">
            <h6>Our Vision</h6>
            <h2>
              We are a technical internship, coding bootcamp, and networking
              opportunity crafted into an all-in-one extracurricular experience.
              We enable students to accelerate their learning and prepare them
              to land lucrative software engineering careers.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
